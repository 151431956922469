import './Header.scss';

import React, { Component } from 'react';
import NavBarController from "../../navBar/NavBarController";
import ButtonRoles from "../../buttonRoles/ButtonRoles";
import { Trans } from 'react-i18next';


class Header extends Component {


    render() {
        // const loading = this.state.loading;
        return (
            // 

            <div className="div-rv-entreprise">
                <NavBarController />
                <div className="container-rv-entreprise">
                    <div className="rv-entreprise1 title-rv1"><Trans i18nKey="header0">Bienvenue sur le</Trans></div>
                    <div className="rv-entreprise2 title-rv2"><Trans i18nKey="header1">Répertoire Vert</Trans></div>
                    <div className="rv-entreprise-text title-rv-text">
                        <Trans i18nKey="header2">Le répertoire professionnel du développement durable offre le référencement et l’évaluation</Trans>
                        <br />
                        <Trans i18nKey="header3">de tous les produits et services professionnels “verts” proposés dans un rayon de 130km par rapport à Genève</Trans>
                    </div>
                </div>
                <ButtonRoles />

            </div >

        )
    }
}

export default Header;