import qrcode from '../../../../public/images/homeCompany/qr-code-appli-repertoire-vert.png'
import apprv from '../../../../public/images/homeCompany/Groupe 94@2x.png'
import React, {Component} from 'react';

class Application extends Component {


    render() {
        return (
            <div className="logos-qrcode">
                <img
                    className="qrcode" src={qrcode}/>
                        <img class="app-rv" src={apprv}/>
                        </div>

    )
    }
    }

    export default Application;