// ./assets/js/components/Home.js

import React, {Component} from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import ProductionResponsable from "./productionResponsable/ProductionResponsable";
import SecteurActivite from "./secteurActivite/SecteurActivite";
import Categories from "./categories/Categories";
import ModeleConcept from "./modeleConcept/ModeleConcept";
import EvaluationSystem from "./evaluationSystem/EvaluationSystem";
import AssociatedPrograms from "./associatedPrograms/AssociatedPrograms";
import Application from "./application/Application";
import Partners from "./partners/Partners";
import Entraide from "./entraide/Entraide";

class HomeCompany extends Component {


    render() {
        return (
            <>
                <Header />
                <ProductionResponsable />
                <SecteurActivite />
                <Categories />
                <ModeleConcept />
                <EvaluationSystem />
                <AssociatedPrograms />
                <Application />
                <Partners />
                <Entraide />
            </>

        )
    }
}

export default HomeCompany;
