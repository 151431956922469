import './Categories.scss';
// import "../../pages/homepage/homepage.scss";
import React, {Component} from 'react';
import axios from 'axios';
import { Trans } from 'react-i18next';


class Categories extends Component {

    constructor(props){
        super(props);
        this.state = {categories: []};
    }
    componentDidMount() {
        axios.get(`/rest/category/`)
            .then(res => {
                const categories = res.data;
                this.setState({categories: categories });
                console.log(categories);
            })
    }

    render() {

        return (

            <div className="div-cards">
                <div className="card_container">
                    
                {
                    this.state.categories
                        .map(category =>
                            <div className="article_card card_mrgr card_mrgb" key={category.id}>
                                <a className="atag" href={`/category/${category.slug}`}>
                                    <div className="card_image" >
                                        <img src={`/images/category/card/${category.image}`} alt={category.name}/>
                                    </div>
                                    <div className="card_content">
                                        <span className="card_category"><Trans i18nKey={category.slug}>{category.name}</Trans ></span>
                                    </div>
                                </a>
                            </div>
                    )
                }
                </div>


            </div>
        )
    }
}

export default Categories;
