import './ButtonRoles.scss';
import individu from '../../../public/images/homeCompany/individu.png';
import entreprise from '../../../public/images/homeCompany/entreprise.png';
import commune from '../../../public/images/homeCompany/commune.png';

import React, {Component} from 'react';

class ButtonRoles extends Component {


    render() {
        let url404town="/404/town";
        let url404individu="/404/individu";

        return (
            <div className="div-btn-role">
                <div className="container-btn-role div-choix-jesuis">
                    <div className="btn-role">

                        <a className="btnRolesCompany" href="/"><img  className="btnRolesimg" src={entreprise} /></a>
                        <a className="btnRolesCompany" href={url404individu}><img className="btnRolesimg" src={individu}/></a>
                        <a className="btnRolesCompany"  href={url404town}><img className="btnRolesimg" src={commune}/></a>

                    </div>
                </div>
            </div>


        )
    }
}

export default ButtonRoles;