
import logo from '../../../../public/images/homeCompany/Icon metro-organization.svg'
import logo2 from '../../../../public/images/homeCompany/Icon material-high-quality.svg'
import logo3 from '../../../../public/images/homeCompany/Icon awesome-recycle.svg'
import logo4 from '../../../../public/images/homeCompany/Icon awesome-envira.svg'
import logo5 from '../../../../public/images/homeCompany/Icon material-directions-transit.svg'
import logo6 from '../../../../public/images/homeCompany/Icon awesome-building.svg'
import logo7 from '../../../../public/images/homeCompany/Icon open-graph.svg'
import logo8 from '../../../../public/images/homeCompany/Icon simple-graphql.svg'
import logo9 from '../../../../public/images/homeCompany/Icon metro-money.svg'

import React, {Component} from 'react';
import { Trans } from 'react-i18next'
import i18n from "../../../i18n";

class EvaluationSystem extends Component {


    render() {
        return (
            <div className="container-evaluationsystem">
                <div>
                    <h2 className="title-evaluationsystem"><Trans i18nKey="evaluation_system0">Système d'évaluation</Trans></h2>
                </div>
                <div className="content-evaluationsystem">
                    <p><Trans i18nKey="evaluation_system1">Il détermine 4 niveaux de référencement basés sur une triple évaluation :</Trans></p><br/>
                    <div className="content-evaluationsystem-list">
                        <div className="list-container1">
                            <li><Trans i18nKey="evaluation_system2">Niveau 0 : Acteur externe</Trans></li>
                            <li><Trans i18nKey="evaluation_system3">Niveau 1 : Membre de l'association
                                <span className="l-bold">gaea21 </span></Trans>
                            </li>
                            <li><Trans i18nKey="evaluation_system4">Niveau 2 : Membre du réseau
                                <span className="l-bold">gaea21 </span></Trans>
                            </li>
                            <li><Trans i18nKey="evaluation_system5">Niveau 3 : Détenteur du label
                                <span className="l-bold">gaea21 </span></Trans>
                            </li>
                            <br/>
                        </div>
                        <div className="list-container2">
                            <h4><Trans i18nKey="evaluation_system6">Critères d'évaluation :</Trans></h4><br/>
                            <li><Trans i18nKey="evaluation_system7">Celle de l'association gaea21</Trans></li>
                            <li><Trans i18nKey="evaluation_system8">Celle des professionnels de la branche d'activité concernée</Trans></li>
                            <li><Trans i18nKey="evaluation_system9">Celle du public (les consom'acteurs)</Trans></li>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default EvaluationSystem;