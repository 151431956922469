import './ProductionResponsable.scss';

import React, {Component} from 'react';
import eye from '../../../../public/images/homeCompany/eye.png';
import economieVerte from '../../../../public/images/homeCompany/image-partie-économie-verte.png';
import { Trans } from 'react-i18next';


class ProductionResponsable extends Component {


    render() {
        return (
            <div className="repertory-description col-l-12">
                <img className="repertory-image" src={economieVerte}/>

                <div className="repertory-content">

                        <div className="title">
                            <h1 className="title"><Trans i18nKey="production_responsible0">Nous créons de la valeur ajoutée et stimulons l'éco-consommation</Trans></h1>
                        </div>

                        <p className="title-content">
                        <Trans i18nKey="production_responsible1">Nous donnons aux consommateurs un outil pour des choix responsables et une solution pour des prix abordables.</Trans>
                        <br/>
                        <Trans i18nKey="production_responsible2">"Vert" est pour nous synonyme d'éco-conscience, de choix et de comportements durables, efficaces et profitables.</Trans>
                        <br/>
                        <Trans i18nKey="production_responsible3">Nous permettons aussi aux entreprises d'être plus visibles, plus impliquées, plus efficaces et plus rentables.</Trans>
                        </p>

                        <div className="responsible-production col-l-12">

                            <div className="responsible-title " >
                                <h3 className="l-green"><Trans i18nKey="production_responsible4">PRODUCTION RESPONSABLE</Trans></h3>
                                <img className="logo-eye" src={eye}/>
                            </div>
                            <div className="responsible-content">
                                <p className="responsible-content-1"><Trans i18nKey="production_responsible5">Le</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible6">Répertoire Vert</Trans> </span>
                                    <Trans i18nKey="production_responsible7">vous fait bénéficier d’une</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible8">meilleure visibilité</Trans> </span>
                                    <Trans i18nKey="production_responsible9">et d’une</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible10">meilleure diffusion</Trans> </span>
                                    <Trans i18nKey="production_responsible11">sur le marché des</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible12">produits</Trans> </span>
                                    <Trans i18nKey="production_responsible13">et</Trans> 
                                    <span className="l-bold"> <Trans i18nKey="production_responsible14">services verts</Trans></span>,
                                    <Trans i18nKey="production_responsible15"> dans le but d'</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible16">accroître</Trans> </span>
                                    <Trans i18nKey="production_responsible17">les</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible18">ventes</Trans> </span>
                                    <Trans i18nKey="production_responsible19">de vos produits et services verts.</Trans></p>

                                <p className="responsible-content-2"><Trans i18nKey="production_responsible20">Grâce au</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible21">processus d’audit</Trans></span>,
                                    <Trans i18nKey="production_responsible22"> vous pourrez non seulement</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible23">diminuer</Trans> </span>
                                    <Trans i18nKey="production_responsible24">votre</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible25">impact environnemental</Trans></span>,
                                    <Trans i18nKey="production_responsible26"> mais aussi</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible27">renforcer</Trans> </span>
                                    <Trans i18nKey="production_responsible28">le</Trans>
                                    <span className="l-bold"> <Trans i18nKey="production_responsible29">capital confiance</Trans> </span>
                                    <Trans i18nKey="production_responsible30">de votre entreprise auprès des éco-consommateurs.</Trans> </p>
                            </div>
                        </div>


                    </div>
            </div>

        )
    }
}

export default ProductionResponsable;