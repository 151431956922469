import './SecteurActivite.scss';
import  "../../../styles/homepage/homepage.scss";
import chercherPS from '../../../../public/images/homeCompany/chercherProduitService.png';
import dansMaRegion from '../../../../public/images/homeCompany/dansMaregion.png';
import rechercherEntreprise from '../../../../public/images/homeCompany/rechercher une entreprise.png';
import rechercherProduit from '../../../../public/images/homeCompany/recherche produit.png';


import React, {Component} from 'react';
import twitter from "../../../../public/css/icon/twitterb.png";
import { Trans } from 'react-i18next';


class SecteurActivite extends Component {


    render() {
        // const loading = this.state.loading;
        let url404="/404/";
        return (
            <div className="activities-sector categories">
                <h3 className="categories-title"><Trans i18nKey="secteurs_activites_titre">Secteurs d'activités</Trans></h3>
                <div className="categories-content">
                    <p><Trans i18nKey="secteurs_activites_contenu">Le Répertoire Vert est divisé en 27 secteurs d'activités, allant de l'alimentation au bâtiment, en passant par les textiles, les médias et bien d'autres encore.</Trans></p>
                </div>

                <div className="m-search-product">
                    <div className="search-functionality col-l-12 col-m-12 col-s-12">
                        <a  href={url404}><img className="search-product-button" src={chercherPS}/></a>
                        <a  href="/companiesinmap"><img className="search-product-button" src={dansMaRegion} /></a>
                        <a  href={url404}><img className="search-product-button" src={rechercherEntreprise}/></a>
                        <a  href={url404}><img className="search-product-button" src={rechercherProduit}/></a>

                </div>
            </div>
            </div>
        )
    }
}

export default SecteurActivite;