
import logo from '../../../../public/images/homeCompany/Icon metro-organization.png'
import logo2 from '../../../../public/images/homeCompany/Icon material-high-quality.png'
import logo3 from '../../../../public/images/homeCompany/Icon awesome-recycle.png'
import logo4 from '../../../../public/images/homeCompany/Icon awesome-envira.png'
import logo5 from '../../../../public/images/homeCompany/Icon material-directions-transit.png'
import logo6 from '../../../../public/images/homeCompany/Icon awesome-building.png'
import logo7 from '../../../../public/images/homeCompany/Icon open-graph.png'
import logo8 from '../../../../public/images/homeCompany/Icon simple-graphql.png'
import logo9 from '../../../../public/images/homeCompany/Icon metro-money.png'

import React, {Component} from 'react';
import { Trans } from 'react-i18next'

class ModeleConcept extends Component {


    render() {
        return (
            <div className="modelconcept-container">
                <div>
                    <h2 className="modelconcept-title"><Trans i18nKey="modele0">Modèle et concept</Trans></h2>
                </div>
                <div className="modelconcept-content">
                    <p><Trans i18nKey="modele0">Le Répertoire Vert est basé sur</Trans>
                        <span className="l-bold"> <Trans i18nKey="modele1">1 QUESTIONNAIRE QUALITATIF ET QUANTITATIF</Trans> </span><br/>
                        <Trans i18nKey="modele2">développé avec la participation de chercheurs et d'experts dans les domaines suivants :</Trans>
                    </p>
                </div>
                <div className="modelconcept-list">
                    <div className="list-one">
                        <div className="list-container">
                            <img className="logo-1" src={logo}
                                 alt="logo"/>
                                <p className="text"><Trans i18nKey="modele3">Lean management & organisation</Trans></p>
                        </div>
                        <div className="list-container">
                            <img className="logo-2" src={logo2} alt="logo"/>
                                <p className="text"><Trans i18nKey="modele4">Cycle de vie de produit</Trans></p>
                        </div>
                        <div className="list-container">
                            <img className="logo-3" src={logo3}
                                 alt="logo"/>
                                <p className="text">
                                <Trans i18nKey="modele5">Qualité de vie au travail</Trans></p>
                        </div>
                        <div className="list-container">
                            <img className="logo-4" src={logo4} alt="logo"/>
                                <p className="text"><Trans i18nKey="modele6">Système de gestion environnemental</Trans></p>
                        </div>
                        <div className="list-container">
                            <img className="logo-5" src={logo5} alt="logo"/>
                                <p className="text"> <Trans i18nKey="modele7">Mobilité & transport</Trans> </p>
                        </div>

                    </div>

                    <div className="list-two">
                        <div className="list-container">
                            <img className="logo-6" src={logo6} alt="logo"/>
                                <p><Trans i18nKey="modele8">Responsabilité sociale de l'entreprise</Trans></p>
                        </div>
                        <div className="list-container">
                            <img className="logo-7" src={logo7} alt="logo"/>
                                <p><Trans i18nKey="modele9">Etude d'impact</Trans></p>
                        </div>
                        <div className="list-container">
                            <img className="logo-8" src={logo8} alt="logo"/>
                                <p><Trans i18nKey="modele10">Gestion de projet</Trans></p>
                        </div>
                        <div className="list-container">
                            <img className="logo-9" src={logo9} alt="logo"/>
                                <p><Trans i18nKey="modele11">Management durable</Trans></p>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default ModeleConcept;