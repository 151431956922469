import './Partners.scss';

import React, {Component} from 'react';
import logoIcon from '../../../../public/images/quisommesnous/logo_gaea21_blanc.png'
import logoEgoMinds from '../../../../public/images/quisommesnous/logo_eco-mind_rvb_fr_blanc.png'
import logoGreenCoaching from '../../../../public/images/quisommesnous/Logo GCBO blanc RVB.png'
import logo_slp from '../../../../public/images/quisommesnous/logo_slp blanc.png'
import { Trans } from 'react-i18next';

class Partners extends Component {


    render() {
        return (

            <div className="divPartenaires">
                <div>
                    <h1 className="partenairsTitle"><Trans i18nKey="main_partners">Partenaires</Trans></h1>
                </div>
                <div className="four-partnairs">

                    <div className="partnairs-div">
                        <div className="logoIcon">
                            <img className="logoIcon" src={logoIcon}/>
                        </div>
                        <div className="text-partnairs">
                            <p>
                            Gaea21 est un think tank, une plateforme
                            de facilitation, un réseau de compétences
                            pluridisciplinaires, un observatoire du
                            développement durable et un centre de
                            recherche appliquée.
                            </p>                            
                            <div className="namePartnair">Yvan Claude</div>
                            <div className="founderPartenaire">Fondateur, gaea21</div>
                        </div>

                    </div>

                    <div className="partnairs-div">
                        <div className="logoIcon">
                            <img className="logoEcoMinds"
                                 src={logoEgoMinds}/>
                        </div>
                        <div className="text-partnairs">
                            <p>Eco-minds est une agence de communication responsable. Au-delà de sa dimension éco-consciente matérialisée par 10 dimensions de green coaching, l'agence  se différencie par un modèle de communication globale et une approche à la fois sociale et éco-citoyenne.                            
                            </p>
                            <div className="namePartnair">Bastien</div>
                            <div className="founderPartenaire">Co-fondateurs, eco minds</div>
                        </div>
                    </div>

                    <div className="partnairs-div">
                        <div className="logoIcon">
                            <img className="logoGreenCoaching" src={logoGreenCoaching}
                                 />
                        </div>
                        <div className="text-partnairs">
                            <p>Le programme green coaching Business
                                & Organisations de gaea21 permet
                                aux entreprises et organisations de
                                réduire l’impact de leur activité tout
                                en augmentant l’ecacité de leurs
                                processus internes
                            </p>
                            <div className="namePartnair">Yvan Claude</div>
                            <div className="founderPartenaire">Fondateur, GCBO</div>
                        </div>
                    </div>

                    <div className="partnairs-div">
                        <div className="logoIcon">
                            <img className="logo_slp" src={logo_slp}/>
                        </div>
                        <div className="text-partnairs">
                            <p>Le programme SLP est un outil de transformation de votre quotidien dans la mesure où
                                il vous propose des outils et des solutions concrètes vous permettant d'améliorer votre
                                expérience de vie tout en préservant notre planète.
                            </p>
                            <div className="namePartnair">Yvan Claude</div>
                            <div className="founderPartenaire">Fondateur, SLP</div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Partners;